.detailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -120px;
  width: 85%;
  margin-right: 90px;
  margin-left: 90px;
}


.detailContainer img {
  width: 250px;
  height: 250px;
  margin-right: 40px;
  margin-top: -5;
  border-radius: 12px;
}

.description {
  display: flex;
  align-items: center;
}
.description div,
.description p {
  margin: 20px 30px;
}
.description h2 {
  margin-top: 10px;
  font-family: "Flexo-Demi", arial, sans-serif;
  color: #313131;
  font-size: 30px;
  text-transform: none;
  margin-bottom: 5px;
}

.description .p_id {
  font-family: "Flexo-Bold", arial, sans-serif;
  color: #0510ac;
  font-size: 150%;
  padding-top: 2px;
}

.detailContainer .statsChart {
  display: flex;
  width: 100%;
  justify-content: center;
}
.statsChart .singleBar {
  width: 100%;
  float: left;
  margin-left: 2%;
  margin-right: 2%;
}
.statsChart .singleBar .bar {
  position: relative;
  height: 200px;
  opacity: calc(0.7);
  border-width: 1px;
  border-style: solid;
  border-color: #313131;
  border-radius: 12px;
  background: rgba(10, 206, 236, 0.931);
  overflow: hidden;
}
.statsChart .singleBar .bar .value {
  position: absolute;
  border-radius: 12px;
  bottom: 0;
  margin-left: 6px;
  margin-bottom: 6px;
  width: 85%;
  background: #ff0000;
  border-width: 2px;
  border-style: solid;
  border-color: #313131;
  color: #c6c77a;
}
.statsChart .singleBar .bar .value span {
  position: absolute;
  font-size: 12px;
  bottom: 0;
  width: 100%;
  height: 20px;
  color: #7a7fc7;
  text-shadow: 0px -1px 0px #7f7ac7, 0px 1px 0px #7a7fc7, 1px 0px 0px #7aa7c7,
    -1px 0px 0px #7aa7c7, -1px -1px 0px #7aa7c7, -1px 1px 0px #7aa7c7,
    1px 1px 0px #7aa7c7, 1px -1px 0px #7aa7c7;
  display: none;
  text-align: center;
}
.statsChart .singleBar .title {
  margin-top: 10px;
  text-align: center;
  color: #0a088f;
  font-weight: bold;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.physical {
  display: flex;
}
.physical p {
  padding: 10px 15px;
  margin: 30px;
  background-color: rgb(219, 219, 219);
  border-radius: 15px;
  text-align: center;
}

@media (max-width: 1300px) {
  .detailContainer{
      
      align-items: center;
      margin-right: 30px;
      margin-left: 30px;
      
  }
}
