@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  margin-right: 90px;
  margin-left: 90px;
  
}

.spinnerStyle {
  width: 250px;
  height: 250px;
  margin-bottom: 24vh;
}

.spinnerContainer {
  width: 100%;
  margin: auto;
  text-align: center;
  
}

.refresh_span {
  font-family:Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #071cc1;
  font-size: 30px;
  letter-spacing: 1.5px;
}

@media (max-width: 1300px) {
  .homeContainer{
      align-items: center;
      margin-right: 10px;
      margin-left: 10px;
      
  }
}
