/* Search button in NavHome */
.searchBox {
  width: fit-content;
  height: fit-content;
  position: absolute;
}
.inputSearch {
  height: 50px;
  width: 50px;
  border-style: none;
  margin-top: 30px;
  margin-left: 200px;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: #1004c5;
  padding-right: 40px;
  color: black;
}
.inputSearch::placeholder {
  color: black;
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btnSearch {
  width: 50px;
  height: 50px;
  margin-top: 30px;
  margin-left: 100px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: rgb(236, 229, 229);
  background-color: transparent;
  pointer-events: painted;
}
.btnSearch:focus ~ .inputSearch {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid #fc466b;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.btnSearch:focus {
  color: black;
}
.inputSearch:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid #fc466b;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
/*         */

@media (max-width: 1300px) {
  .searchBox {
  width: fit-content;
  height: fit-content;
  position: absolute;
}
}

