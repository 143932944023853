@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.detailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -100px;
  width: 100%;
}

.background {
  background: url("https://assets.pokemon.com//assets/cms2-es-es/img/misc/virtual-backgrounds/sword-shield/pokemon-in-the-wild.png") fixed; 
     height: 100vh; 
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -100px;
  width: 100%;
}

.homeBtn {
  background-image: url("../assets/homeButton.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  position: relative;
  left: 30px;
  top: 300px;
  transition: transform 0.7s ease-in-out; /* Spin animation */
}



.home_span {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif, sans-serif;
  position: relative;
  left: 0px;
  top: 300px;
  text-decoration: none;
  color: #0716f3;
  font-size: 49px;
  letter-spacing: 2px;
}

.homeBtn:hover {
  transform: rotate(360deg) scale(1.5);
}

@media (max-width: 375px) {
  .detailContainer{
      align-items: center;
  }
}

.background {
  background: none; 
     height: 0vh; 
    background-position-x: center;
    background-repeat: no-repeat;
   
    display: flex;
    align-items: center;
    flex-direction: column;
  align-items: center;
}