.navhome_ul {
  display: flex;
  list-style-type: none;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  /*margin-left: 150px;*/
}
.navhome_ul li {
  margin: 5px;
}

.aboutBtn {
  /* Remove default style of button */
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit; 
  text-decoration: none;
  list-style-type: none;
}

.aboutBtn .question {
  color: #ff0a0a;
  transition: all 1.5s;
}

.aboutBtn .question:hover {
  transform: scale(1.8);
}